<template>
  <validation-provider
    v-slot="validationContext"
    :ref="`text-${question.id}`"
    :rules="{required: question.is_required}"
    :vid="`text-${question.id}`"
    name="Answer"
  >
    <b-card
      no-body
    >
      <b-card-header
        header-text-variant="dark"
        header-bg-variant="light-primary"
      >
        <b-row no-gutters>
          <b-col
            sm="12"
          >
            <b-row>
              <b-col
                sm="12"
                class="pr-0"
              >
                {{ questionNumber }}. <span v-html="linkifyStr(questionHeader, linkOptions)">{{ questionHeader }}</span>
              </b-col>
              <b-col
                sm="12"
                class="text-muted"
              >
                <span class="vibility-hidden">{{ questionNumber }}.</span> {{ getTranslationTextByUserLocale(question.translations, 'prefilled_answer', preferredLanguageId) }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            class="d-flex justify-content-end"
          >
            <feather-icon
              v-if="question.is_visible"
              class="mr-50"
              icon="EyeIcon"
              size="16"
            /><span
              v-if="question.is_visible"
              class="d-none d-sm-block"
            >Visible to matched partner</span>
            <b-link 
              v-if="readOnly && allowEdit"  
              class="ml-50 mr-50"
              @click="$emit('edit', question)"
            >
              <feather-icon
                class="ml-50 mr-50"
                icon="EditIcon"
                size="16"
              /><span class="d-none d-sm-inline-block">Edit</span>
            </b-link>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body body-border-variant="light">
        <b-form-group class="p-1">
          <b-form-textarea
            id="text-answer"
            ref="textAnswer"
            v-model="answer_text"
            class="shadow-sm"
            aria-describedby="question-error"
            maxlength="500"
            aria-placeholder="Please provide a response"
            placeholder="Please provide a response"
            :disabled="readOnly"
            :state="
              getValidationState(
                validationContext
              )
            "
          />
          <b-form-invalid-feedback
            :state="
              getValidationState(
                validationContext
              )
            "
          >
            {{
              validationContext.errors[0]
            }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-card-body>
    </b-card>
  </validation-provider>
</template>
  
<script>
import { ValidationProvider } from "vee-validate";
import { 
  BFormTextarea, 
  BFormGroup, 
  BFormInvalidFeedback, 
  BCard, 
  BCardHeader, 
  BCardBody, 
  BRow, 
  BCol, 
  BLink, 
} from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";
import { required } from "@validations";
import { mapGetters } from 'vuex';
import { locales } from '@models';
import linkifyStr from 'linkify-string';

export default {
  name: "ApplicationSingleTextBox",
  components: {
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    BFormInvalidFeedback,
    BCard, 
    BCardHeader, 
    BCardBody,
    BRow,
    BCol, 
    BLink
  },
  props: {
    question: {
      type: Object,
      default: () => {},
      required
    },
    index: {
      type: Number,
      default: 0,
      required
    },
    preferredLanguageId: {
      type: Number,
      default: locales.EN
    }, 
    readOnly: {
      type: Boolean
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      answer_text: '',
      linkOptions: {
        className: 'external_link',
        target: '_blank',
      }
    };
  },
  computed: {
    ...mapGetters('participants',['getTranslationTextByUserLocale']),
    ...mapGetters("participants", ["answerData"]),
    questionHeader() {
      const trans = this.getTranslationTextByUserLocale(this.question.translations, 'question_text', this.preferredLanguageId);
      return `${trans}`;
    },
    questionNumber() {
      return Number(this.index || this.question.question_order) + 1;
    }
  },
  watch: {
    question: {
      handler(newValue, oldValue) {
        if(newValue !== oldValue) {
          this.answer_text = this.question.answers[0]?.answer_text;
        }
      },
      deep: true,
      immediate: true
    },
    answer_text(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.question.answers = [{
          answer_text: newValue,
          question_id: this.question?.id
        }];
      }
    }
  },
  setup() {
    return {
      getValidationState,
      linkifyStr
    };
  }
};
</script>
<style lang="scss" scoped>
.margin-left-18 {
  margin-left: 18px;
}
.vibility-hidden {
  visibility: hidden;
}
</style>
