<template>
  <b-card
    no-body
  >
    <b-card-header
      header-text-variant="dark"
      header-bg-variant="light-primary"
    >
      <b-row no-gutters>
        <b-col
          sm="12"
        >
          <b-row>
            <b-col
              sm="12"
              class="pr-0"
            >
              {{ questionNumber }}.  <span v-html="linkifyStr(questionHeader, linkOptions)">{{ questionHeader }}</span>
            </b-col>
            <b-col
              sm="12"
              class="text-muted"
            >
              <span class="vibility-hidden">{{ questionNumber }}.</span> {{ getTranslationTextByUserLocale(question.translations, 'prefilled_answer') }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>  
      <b-row>
        <b-col
          sm="12"
          class="d-flex justify-content-end"
        >
          <span
            v-if="question.is_visible"
            class="d-flex align-items-center"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
              size="16"
            /><span class="d-none d-sm-block">Visible to matched partner</span>
          </span>
          <b-link 
            v-if="readOnly && allowEdit"  
            class="ml-50 mr-50"
            @click="$emit('edit', question)"
          >
            <feather-icon
              class="ml-50 mr-50"
              icon="EditIcon"
              size="16"
            /><span class="d-none d-sm-inline-block">Edit</span>
          </b-link>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body
      body-border-variant="light"
      class="overflow-auto"
    >
      <validation-provider
        v-slot="{ errors }"
        :ref="`provider-${question.id}`"
        :vid="`rating-scale-${question.id}`"
        :rules="question.is_required ? `rating-scale-${question.id}`: ''"
        :name="`ratingScale-${question.id}`"
      >
        <table class="table mt-2 mb-2">
          <thead>
            <tr>
              <th class="bg-light" />
              <th
                v-for="(qCol, thIndex) in question.choices"
                :key="thIndex"
                class="bg-light"
              >
                {{
                  getTranslationTextByUserLocale(qCol.translations, "choice_text")
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(qRow, qIndex) in question.statements"
              :key="qIndex"
            >
              <td class="bg-light">
                {{
                  getTranslationTextByUserLocale(
                    qRow.translations,
                    "question_text"
                  )
                }}
              </td>
          
              <td
                v-for="(aCol, aIndex) in question.choices"
                :key="aIndex"
                class="text-center choices"
              >
                <b-form-radio
                  v-model="selected[qIndex]"
                  :aria-label="
                    getTranslationTextByUserLocale(
                      qRow.translations,
                      'question_text'
                    ) +
                      ' - ' +
                      getTranslationTextByUserLocale(
                        aCol.translations,
                        'choice_text'
                      )
                  "
                  :value="{choice_id: aCol.id, question_id: qRow.id, statement_index: qIndex, current_question_id: question.id}"
                  :checked="aCol.id === selected[qIndex].choice_id"
                  tabindex="0"
                  :disabled="readOnly"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="errors"
          class="d-block invalid-feedback"
        >
          <span>{{ errors[0] }}</span>
        </div>
      </validation-provider>
    </b-card-body>
  </b-card>
</template>
    
<script>
import { ValidationProvider, extend } from "vee-validate";
import { BFormRadio, BCard, BCardHeader, BCardBody, BRow, BCol, BLink } from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";
import _isEqual from 'lodash/isEqual';
import { locales } from '@models';
import linkifyStr from 'linkify-string';

export default {
  name: "AomApplicationRating",
  components: {
    BCard, 
    BCardHeader, 
    BCardBody, 
    BFormRadio,
    ValidationProvider,
    BRow,
    BCol,
    BLink
  },
  props: {
    question: {
      type: Object,
      default: () => {},
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    preferredLanguageId: {
      type: Number,
      default: locales.EN
    },
    readOnly: {
      type: Boolean
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: [],
      linkOptions: {
        className: 'external_link',
        target: '_blank',
      }
    };
  },
  computed: {
    questionHeader() {
      const trans = this.getTranslationTextByUserLocale(this.question.translations,'question_text');
      return `${trans}`;
    },
    questionNumber() {
      return Number(this.index || this.question.question_order) + 1;
    }
  },
  watch: {
    question: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        const answers = this.question.statements.map((statement, index) => {
          if (statement.answers && statement.answers.length > 0) {
            return {
              'choice_id': statement.answers[0].choice_id,
              'question_id': statement.answers[0].question_id,
              'statement_index': index,
              'current_question_id': this.question.id
            };
          } else {
            return {
              'choice_id': undefined,
              'question_id': undefined,
              'statement_index': index,
              'current_question_id': this.question.id
            };
          }
        });
        this.selected = answers;
      },
      deep: true,
      immediate: true
    },
    selected: {
      handler(n) {
        const t = n.filter(choice => choice.choice_id && choice.question_id);
        if(t.length > 0) {
          t.forEach(ans => {
            if(this.question.statements[ans.statement_index] !== undefined) {
              this.question.statements[ans.statement_index].answers = [ans];
            }
          });
        }
        const ref = `provider-${this.question.id}`;
        const provider = this.$refs[ref];
        if(provider) {
          return provider.validate();
        }
      },
      immediate: true,
    },
  },
  created() {
    extend(`rating-scale-${this.question.id}`, {
      validate: () => {
        const unAns = this.selected.filter(s => !s.choice_id);
        return unAns.length === 0;
      },
      message: 'A choice for each statement is required'
    });
  },
  methods: {
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName,
        this.preferredLanguageId
      );
    }
  },
  setup() {
    return {
      getValidationState,
      linkifyStr
    };
  }
};
</script>
<style lang="scss" scoped>
.margin-left-18 {
  margin-left: 18px;
}
.vibility-hidden {
  visibility: hidden;
}
</style>   
  