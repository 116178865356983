var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-text-variant":"dark","header-bg-variant":"light-primary"}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12"}},[_vm._v(" "+_vm._s(_vm.questionNumber)+". "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.linkifyStr(_vm.questionHeader, _vm.linkOptions))}},[_vm._v(_vm._s(_vm.questionHeader))])]),_c('b-col',{staticClass:"text-muted",attrs:{"sm":"12"}},[_c('span',{staticClass:"vibility-hidden"},[_vm._v(_vm._s(_vm.questionNumber)+".")]),_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale(_vm.question.translations, 'prefilled_answer'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[(_vm.question.is_visible)?_c('span',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v("Visible to matched partner")])],1):_vm._e(),(_vm.readOnly && _vm.allowEdit)?_c('b-link',{staticClass:"ml-50 mr-50",on:{"click":function($event){return _vm.$emit('edit', _vm.question)}}},[_c('feather-icon',{staticClass:"ml-50 mr-50",attrs:{"icon":"EditIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v("Edit")])],1):_vm._e()],1)],1)],1),_c('b-card-body',{staticClass:"overflow-auto",attrs:{"body-border-variant":"light"}},[_c('validation-provider',{ref:("provider-" + (_vm.question.id)),attrs:{"vid":("rating-scale-" + (_vm.question.id)),"rules":_vm.question.is_required ? ("rating-scale-" + (_vm.question.id)): '',"name":("ratingScale-" + (_vm.question.id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('table',{staticClass:"table mt-2 mb-2"},[_c('thead',[_c('tr',[_c('th',{staticClass:"bg-light"}),_vm._l((_vm.question.choices),function(qCol,thIndex){return _c('th',{key:thIndex,staticClass:"bg-light"},[_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale(qCol.translations, "choice_text"))+" ")])})],2)]),_c('tbody',_vm._l((_vm.question.statements),function(qRow,qIndex){return _c('tr',{key:qIndex},[_c('td',{staticClass:"bg-light"},[_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale( qRow.translations, "question_text" ))+" ")]),_vm._l((_vm.question.choices),function(aCol,aIndex){return _c('td',{key:aIndex,staticClass:"text-center choices"},[_c('b-form-radio',{attrs:{"aria-label":_vm.getTranslationTextByUserLocale(
                    qRow.translations,
                    'question_text'
                  ) +
                    ' - ' +
                    _vm.getTranslationTextByUserLocale(
                      aCol.translations,
                      'choice_text'
                    ),"value":{choice_id: aCol.id, question_id: qRow.id, statement_index: qIndex, current_question_id: _vm.question.id},"checked":aCol.id === _vm.selected[qIndex].choice_id,"tabindex":"0","disabled":_vm.readOnly},model:{value:(_vm.selected[qIndex]),callback:function ($$v) {_vm.$set(_vm.selected, qIndex, $$v)},expression:"selected[qIndex]"}})],1)})],2)}),0)]),(errors)?_c('div',{staticClass:"d-block invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }