<template>
  <section>
    <b-card no-body>
      <b-card-header
        header-text-variant="dark"
        header-bg-variant="light-primary"
      >
        <b-row no-gutters>
          <b-col
            sm="12"
          >
            <b-row>
              <b-col
                sm="12"
                class="pr-0"
              >
                {{ questionNumber }}. <span v-html="linkifyStr(questionHeader, linkOptions)"><b><u>{{ questionHeader }}</u></b></span>
              </b-col>
              <b-col
                sm="12"
                class="text-muted"
              >
                <span class="vibility-hidden">{{ questionNumber }}.</span> {{ getTranslationTextByUserLocale(question.translations, 'prefilled_answer') }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>  
        <b-row>
          <b-col
            sm="12"
            class="d-flex justify-content-end"
          >
            <span
              v-if="question.is_visible"
              class="d-flex align-items-center"
            >
              <feather-icon
                class="mr-50"
                icon="EyeIcon"
                size="16"
              /><span class="d-none d-sm-block">Visible to matched partner</span>
            </span>
            <b-link 
              v-if="readOnly && allowEdit"  
              class="ml-50 mr-50"
              @click="$emit('edit', question)"
            >
              <feather-icon
                class="ml-50 mr-50"
                icon="EditIcon"
                size="16"
              /><span class="d-none d-sm-inline-block">Edit</span>
            </b-link>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body body-border-variant="light">
        <b-row>
          <validation-provider
            v-slot="validationContext"
            :ref="`choice-${question.id}`"
            :rules="{required: question.is_required}"
            :vid="`choice-${question.id}`"
            name="Choice"
          >
            <b-col
              md="12"
              class="mt-2 pl-3 pr-3 pb-2"
            >
              <b-form-checkbox-group
                v-if="isMultiAnswer"
                v-model="selected"
                stacked
                :options="options"
                tabindex="0"
                :disabled="readOnly"
              />
              <b-form-radio-group
                v-if="!isMultiAnswer"
                v-model="selected"
                stacked
                :options="options"
                tabindex="0"
                :disabled="readOnly"
              />
              <b-form-invalid-feedback
                :state="
                  getValidationState(
                    validationContext
                  )
                "
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </b-col>
          </validation-provider>
        </b-row>
        <div 
          v-if="isChoiceOtherSelected"
          class="p-0 mb-1"
        >
          <validation-provider
            v-slot="validationContext"
            :ref="`other-text-${question.id}`"
            rules="required"
            :vid="`other-text-${question.id}`"
            name="Other Answer"
          >
            <b-form-textarea
              ref="otherAnswer"
              v-model="answerTextOther"
              :placeholder="otherPlaceHolderText"
              tabindex="0"
              aria-describedby="other-question"
              aria-label="Other Answer"
              class="form-control"
              maxlength="500"
              :disabled="readOnly"
              :state="
                getValidationState(
                  validationContext
                )
              "
              @change="onchangeAnswerOther()"
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>
    
<script>
import { ValidationProvider } from "vee-validate";
import { 
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormTextarea,
  BCard,
  BCardHeader,
  BCardBody, 
  BLink
} from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";

import { required } from "@validations";
import _isEqual from 'lodash/isEqual';
import { locales } from '@models';
import linkifyStr from 'linkify-string';

export default {
  name: "AoMApplicationMultipleChoice",
  components: {
    BRow,
    BCol,
    ValidationProvider,
    BFormInvalidFeedback, 
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormTextarea,
    BCard,
    BCardHeader,
    BCardBody,
    BLink
  },
    
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
      required
    },
    preferredLanguageId: {
      type: Number,
      default: locales.EN
    },
    readOnly: {
      type: Boolean
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
    
  data() {
    return {
      answers: [...this.question.answers],
      is_locked: false,
      answerTextOther: "",
      selected: [],
      options: [],
      otherPlaceHolderText: '',
      linkOptions: {
        className: 'external_link',
        target: '_blank',
      }
    };
  },
  computed: {
    isMultiAnswer() {
      return Number(this.question.choice_limit) >  Number(1);
    },
    answerChoices() {
      return this.question.choices;
    },
    choiceOther() {
      return this.question.choices.find(choice => choice.is_other);
    },
    isChoiceOtherSelected() {
      if(this.isMultiAnswer && this.choiceOther) {
        return this.selected.includes(this.choiceOther.id);
      } 
      if(!this.isMultiAnswer && this.choiceOther) {
        return this.selected === this.choiceOther.id;
      }
      return false;
    },
    questionHeader() {
      const trans = this.getTranslationTextByUserLocale(this.question.translations,'question_text');
      return `${trans}`;
    },
    questionNumber() {
      return Number(this.index || this.question.question_order) + 1;
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if(_isEqual(newValue, oldValue) || newValue === undefined) {
        return;
      }
      this.setOptionsDisabled();
      this.handlePayload();
    },
    question: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if(n) {
          this.setQuestionOptions();
          this.setOptionsDisabled();
         
          if(this.choiceOther) {
            const otherAnswer = this.question.answers.find(
              answer => answer.choice_id === this.choiceOther.id
            );
            if (otherAnswer) {
              this.answerTextOther = otherAnswer.answer_text;
            } else {
              if(this.choiceOther) {
                this.otherPlaceHolderText = this.getTranslationTextByUserLocale(
                  this.choiceOther.translations,
                  "choice_text",
                );
              }
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setOptionsDisabled () {
      if(this.selected && this.selected.length === Number(this.question.choice_limit)) {
        this.options = this.answerChoices.map(o => {
          if(!this.selected.includes(o.id)) {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text",
            ), 
            value: o.id, 
            disabled: true 
            };
          } else {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text",
            ), 
            value: o.id, 
            disabled: false 
            };
          }
        });
      } else {
        this.options = this.answerChoices.map(o => ({ text: this.getTranslationTextByUserLocale(
          o.translations,
          "choice_text",
        ), value: o.id, disabled: false }));
      }
    },
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName,
        this.preferredLanguageId
      );
    },
    setQuestionOptions () {
    // Only if answer choice has valid choice
      if(this.isMultiAnswer) {
        this.selected = this.question.answers.
          filter(answer => this.question.choices.
            find(c => c.id === answer.choice_id)).map(ans => ans.choice_id);
      } else {
        this.selected = this.question.answers.filter(answer => this.question.choices.
          find(c => c.id === answer.choice_id)).map(ans => ans.choice_id).shift();
      }
    },
    handlePayload() {
      if(this.isMultiAnswer) {
        this.question.answers = this.selected.map(select =>
          this.choiceOther && select === this.choiceOther.id
            ? {
              question_id: this.question.id,
              choice_id: select,
              answer_text: this.answerTextOther,
            }
            : {
              question_id: this.question.id,
              choice_id: select,
            }
        );
      } else {
        // Single Answer
        this.question.answers = [
          this.choiceOther && this.selected === this.choiceOther.id
            ? {
              question_id: this.question.id,
              choice_id: this.selected,
              answer_text: this.answerTextOther
            }
            : {
              question_id: this.question.id,
              choice_id: this.selected
            }];
      }
      
    },

    onchangeAnswerOther() {
      this.question.answers = this.question.answers.map(answer =>
        this.choiceOther && answer.choice_id === this.choiceOther.id
          ? { ...answer, answer_text: this.answerTextOther }
          : answer
      );      
    },
  },
  setup() {
    return {
      getValidationState,
      linkifyStr
    };
  }
};
</script>
<style lang="scss" scoped>
.margin-left-18 {
  margin-left: 18px;
}
.vibility-hidden {
  visibility: hidden;
}
</style>   